import React, { useEffect, useState } from 'react';
import Inputs from './inputs';
import SelectInputs from './selectInputs';
import ButtonLoad from './buttonLoad';

const EditableTable = (props) => {
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [res, setRes] = useState({
    message: '',
    type: ''
  })
  useEffect(() => {
    // Initialize formData with props.data when the modal opens
    if (props.data) {
      setFormData(props.data);
    }
  }, [props.data, props.show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    props.setData((prev) => ({ ...prev, [name]: value }));
  };
  const update = async () => {
    setIsLoading(true);
    if (props.data) {
      
    }
    // Perform the update operation here
    try {
      const response = await fetch(props.link, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(props.data),
      })
      const result = await response.json()
      if (result.status === 'Success') {
        setRes({...res, message: result.message, type: true})
        // window.location.reload()
      }else{
        setRes({...res, message: result.message, type: false})
      }
      setIsLoading(false)
    } catch (error) {
      console.error("Error:", error)
    }
  };
  return (
    <div className={`modal fade ${props.show ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: props.show ? 'rgba(0,0,0,0.5)' : 'transparent' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.value}</h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={props.handleClose}></button>
          </div>
          <div className="modal-body">
            {res.message && <p className={`w3-tag bg-${(res.type) ? 'success' : 'danger'} w3-round text-center w3-text-white`}>{res.message}</p>}
            {(props.inputs || []).map((row, index) => {
              if (row.type === 'text' || row.type === 'number' || row.type === 'email' || row.type === 'tel') {
                return (
                  <Inputs
                    key={index}
                    label={row.name}
                    icon={row.icon}
                    type={row.type}
                    placeholder={row.placeholder}
                    value={formData[row.name]}
                    handleInpt={handleChange}
                    name={row.name}
                  />
                );
              } else {
                return (
                  <SelectInputs
                    key={index}
                    label={row.name}
                    icon={row.icon}
                    values={row.vals}
                    value={formData[row.name]}
                    handleInpt={handleChange}
                    name={row.name}
                  />
                );
              }
            })}
          </div>
          <div className="modal-footer">
            {!isLoading && (
              <button type="button" className={`btn btn-success`} onClick={() => update()}>
                Process
              </button>
            )}
            {isLoading && <ButtonLoad value='processing...' />}
          </div>
        </div>
      </div>
    </div>
  );
};


export default EditableTable;
