import React, { useContext, useEffect, useState } from 'react'
import Top from '../components/top'
import Table from '../components/table'
import { MainContext } from '../contexts/mainContext'
import { AuthContext } from '../contexts/authContext'
import { useNavigate } from 'react-router-dom'

const Groups = () => {
    const {
        authUser,
        setAuthUser,
        isLogged,
        setIsLogged
    } = useContext(AuthContext)
    const {
      transactions,
      setTransactions,
      latestTransactions,
      setLatesTransactions,
      users,
      setLatestUsers,
      wallets,
      setWallets,
      admins,
      setAdmins,
      payment,
      setPayment,
      groups,
      setGroups,
      currency,
      setCurrency,
      category,
      setCategory,
      controls,
      setControls,
      latestUsers,
      setUsers,
      adminCurrency,
      setAdminCurrency,
      countries,
      setCountries,
      products,
      setProducts,
      isEdit,
      setIsEdit,
      isAdd,
      setIsAdd,
    } = useContext(MainContext)
    const [showModal, setShowModal] = useState(false);
    const link = 'https://callify.club/icoinn_backend/admin/groups.php'
    let groupColumns = ['GroupID','Link', 'Platform', 'Country', 'Edit']
    // let groups = [
    //     {
    //         country: 'Kenya',
    //         group: '[link]',
    //     },
    //     {
    //         country: 'Tanzania',
    //         group: '[link]',
    //     }
    // ]
    const inputs = [
      {
        name: "link",
        icon: 'fa-solid fa-cube',
        type: 'text',
        placeholder: 'Enter Link address',
      },
      {
        name: "platform",
        icon: 'fa-solid fa-user',
        type: 'text',
        placeholder: 'Enter Platform(name that will display)',
      },
      {
        name: "country",
        icon: 'fa-solid fa-user',
        type: 'select',
        vals: countries
      }
      
    ]
    function addGroup(){
        window.location.replace('/edit')
    }
    const navigate = useNavigate()
    useEffect(() => {
      if (!localStorage.getItem('udm_unm')) {
        navigate('/')
      }
    }, [navigate])
  return (
    <div style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
        <Top showModal={showModal} setShowModal={setShowModal} isAdd={isAdd} value='Add New Group' name='Groups'/>
        <div className="w3-container">
            <Table tableName='groups' link={link} inputs={inputs} showModal={showModal} setShowModal={setShowModal} countries={countries} name='Groups Available' columns={groupColumns} data={groups} summary={false} edit={isEdit}/>
        </div>
    </div>
  )
}

export default Groups