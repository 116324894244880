import React, { useContext, useEffect, useState } from 'react'
import Button from '../components/button'
import EditableTable from '../components/editableTable'
import Table from '../components/table'
import Top from '../components/top'
import { AuthContext } from '../contexts/authContext'
import { MainContext } from '../contexts/mainContext'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Product = () => {
    const {
        authUser,
        setAuthUser,
        isLogged,
        setIsLogged
    } = useContext(AuthContext)
    const {
      transactions,
      setTransactions,
      latestTransactions,
      setLatesTransactions,
      users,
      setLatestUsers,
      wallets,
      setWallets,
      admins,
      setAdmins,
      payment,
      setPayment,
      groups,
      setGroups,
      currency,
      setCurrency,
      category,
      setCategory,
      controls,
      setControls,
      latestUsers,
      setUsers,
      adminCurrency,
      setAdminCurrency,
      countries,
      setCountries,
      products,
      setProducts,
      isEdit,
      setIsEdit,
      isAdd,
      setIsAdd,
      admInf,
        setAdmInf,
    } = useContext(MainContext)

    const notifySuccess = () => {
      toast.success("Products Updated Successful!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    
    const notifyError = () => {
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    
    
    const [showModal, setShowModal] = useState(false);
    const link = 'https://callify.club/icoinn_backend/admin/products.php'
    let prodColumns = ['PackageID','Package Name', 'Daily Income', 'Country', 'Cycles', 'Price', 'Rol', 'type', 'Image']
    // let products = [
    //     {
    //         name: 'Plan A bd Basic',
    //         cost: 'kes 1,000.00',
    //         daily: 'Kes 40.00',
    //         cycle: '20 days'
    //     },
    //     {
    //         name: 'Plan B bd Basic',
    //         cost: 'kes 2,000.00',
    //         daily: 'Kes 200.00',
    //         cycle: '20 days'
    //     }
    // ]
        
    const inputs = [
      {
        name: "name",
        icon: 'fa-solid fa-cube',
        type: 'text',
        placeholder: 'Enter Package Name',
      },
      {
        name: "daily",
        icon: 'fa-solid fa-user',
        type: 'number',
        placeholder: 'Enter Daily Income',
      },
      {
        name: "country",
        icon: 'fa-solid fa-user',
        type: 'select',
        vals: countries
      },
      {
        name: "days",
        icon: 'fa-solid fa-user',
        type: 'number',
        placeholder: 'Enter cycle',
      },
      {
        name: "cost",
        icon: 'fa-solid fa-user',
        type: 'number',
        placeholder: 'Enter Package Price',
      },
      {
        name: "image",
        icon: 'fa-solid fa-user',
        type: 'text',
        placeholder: 'Enter Package Image',
      }
    ]
    const navigate = useNavigate()
    useEffect(() => {
      if (!localStorage.getItem('udm_unm')) {
        navigate('/')
      }
    }, [navigate])
    const UpdateProds = async() => {
      const postData = {
        type: 'post'
      }
      try {
        const response = await fetch('https://callify.club/icoinn_backend/admin/updateProducts.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        })
        const result = await response.json()
        if (result.status == "Success") {
          notifySuccess()
        }else{
          notifyError()
        }
      } catch (error) {
        
      }
    }
  return (
    <div  style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
            <Top showModal={showModal} setShowModal={setShowModal} isAdd={isAdd} value='Add New Product' name='Products'/>
        <div className="w3-container">
            <Table tableName='products' link={link} inputs={inputs} showModal={showModal} setShowModal={setShowModal} countries={countries} name='Investment Products(currency in USD)' columns={prodColumns} data={products} summary={false} edit={isEdit}/>
            
            <button onClick={UpdateProds} className={`btn btn-success ${admInf && (admInf.role == 'CEO') ? 'w3-show' : 'w3-hide'}`}>Update Products</button>
        </div>
        <ToastContainer/>
    </div>
  )
}

export default Product