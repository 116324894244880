import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from '../contexts/mainContext'
import { AuthContext } from '../contexts/authContext'

const ToWith = () => {
    const {
        authUser,
        setAuthUser,
        isLogged,
        setIsLogged
    } = useContext(AuthContext)
    const {
      transactions,
      setTransactions,
      latestTransactions,
      setLatesTransactions,
      users,
      setLatestUsers,
      wallets,
      setWallets,
      admins,
      setAdmins,
      payment,
      setPayment,
      groups,
      setGroups,
      currency,
      setCurrency,
      category,
      setCategory,
      controls,
      setControls,
      latestUsers,
      setUsers,
      adminCurrency,
      setAdminCurrency,
      countries,
      setCountries,
      products,
      setProducts,
      isEdit,
      setIsEdit,
      isAdd,
      setIsAdd
    } = useContext(MainContext)
    const data = transactions.filter(item => item.status === "Pending" && item.type === "Withdraw") || [];
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 7;
  
    // Calculate the total number of pages
    const totalPages = Math.ceil(data.length / rowsPerPage);
  
    // Get the data for the current page
    const currentData = data.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
    const dataLen = data.length
    let i = 1;
    const [res, setRes] = useState({
        message: '',
        type: ''
      })
    const [postData, setPostData] = useState({
        ID: '',
        email: '',
        amount: '',
        status: '',
        type: '',
        status: ''
    })
    const approve = async(row) => {
        setPostData({...postData, ID:row.ID, email: row.email, amount: row.amount, type: 'Withdraw', status: 'Approved'})
        //post to backend
        try {
            const response = await fetch('https://callify.club/icoinn_backend/admin/pTransactions.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(postData),
            })
            const result = await response.json()
            if (result.status === 'Success') {
              setRes({...res, message: result.message, type: true})
              window.location.reload()
            }else{
              setRes({...res, message: result.message, type: false})
            }
            
          } catch (error) {
            console.error("Error:", error)
          }
    }
    const reject = async(row) => {
        setPostData({...postData, ID:row.ID, email: row.email, amount: row.amount, type: 'Withdraw', status: 'Rejected'})
        //post to backend
        try {
            const response = await fetch('https://callify.club/icoinn_backend/admin/pTransactions.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(postData),
            })
            const result = await response.json()
            if (result.status === 'Success') {
              setRes({...res, message: result.message, type: true})
              window.location.reload()
            }else{
              setRes({...res, message: result.message, type: false})
            }
            
          } catch (error) {
            console.error("Error:", error)
          }
    }
    function formatNumber(number) {
      return number.toLocaleString('en-US', { 
          minimumFractionDigits: 2, 
          maximumFractionDigits: 2 
      });
  }
  return (
    <div style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
        <div className="w3-container">
            <div style={{marginTop: '20px'}} className="w3-row w3-padding w3-round-large w3-white w3-card">
                <div style={{ height: '10vh', width: '100%' }} className="w3-row flex-row w3-padding w3-border-bottom border-grey">
                    <div style={{ width: '5px', height: '5vh' }} className="t-elem w3-round-xlarge"></div>
                    <p className="w3-medium margin-0 bold text-dark padding-sm">
                        Pending Withdrawals
                    </p>
                </div>
                <div className="w3-row w3-padding table-div table-responsive">
                    <table className="table table-hover table-striped">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th >Email</th>
                            <th >Type</th>
                            <th >Amount</th>
                            <th >Account</th>
                            <th >Status</th>
                            <th >Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentData.map((row) => (
                            <tr key={row.ID}>
                                <td>{i++}</td>
                                <td>{row.email}</td>
                                <td>{row.type}</td>
                                <td>{adminCurrency.currency} {formatNumber(row.description * adminCurrency.rate)}</td>
                                <td>{row.account}</td>
                                <td>{row.status}</td>
                                <td > <span className='btn w3-round-large btn-success' onClick={() => approve(row)}>Approve</span> <span className='btn w3-round-large btn-danger' onClick={() => reject(row)}>Reject</span></td>
                            </tr>
                        ))}
                        {dataLen < 1 && (
                            <tr>
                            <td className="w3-large text-center">No data Available</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default ToWith