import React, { useCallback, useContext, useEffect, useState } from 'react'
import Topbar from '../components/topbar'
import Panel from '../components/panel'
import Table from '../components/table'
import {AuthContext} from '../contexts/authContext'
import { MainContext } from '../contexts/mainContext'
import { useNavigate } from 'react-router-dom'


const Dashboard = () => {
  const {
    authUser,
    setAuthUser,
    isLogged,
    setIsLogged
} = useContext(AuthContext)
const {
  transactions,
  setTransactions,
  latestTransactions,
  setLatesTransactions,
  users,
  setLatestUsers,
  wallets,
  setWallets,
  admins,
  setAdmins,
  payment,
  setPayment,
  groups,
  setGroups,
  currency,
  setCurrency,
  category,
  setCategory,
  controls,
  setControls,
  latestUsers,
  setUsers,
  adminCurrency,
  setAdminCurrency,
  countries,
  setCountries,
  products,
  setProducts
} = useContext(MainContext)
  // Calculate the sum of all balances
  const calculateTotalBalance = () => {
    return wallets.reduce((total, item) => {
      // Convert the balance to a number and add it to the total
      return total + parseFloat(item.balance || 0);
    }, 0);
  };
  function formatNumber(number) {
    return number.toLocaleString('en-US', { 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
    });
}
  // Calculate the total balance
  const totalBalance = calculateTotalBalance();

  const calculateTotalDeposits = () => {
    return wallets.reduce((total, item) => {
      // Convert the deposits to a number and add it to the total
      return total + parseFloat(item.deposits || 0);
    }, 0);
  };

  // Calculate the total deposits
  const totalDeposits = calculateTotalDeposits();

  const calculateTotalIncome = () => {
    return wallets.reduce((total, item) => {
      // Convert the total income to a number and add it to the total
      return total + parseFloat(item.totals || 0);
    }, 0);
  };

  // Calculate the total income
  const totalIncome = calculateTotalIncome();

  const calculateTotalWithdrawals = () => {
    return wallets.reduce((total, item) => {
      // Convert the withdrawals to a number and add it to the total
      return total + parseFloat(item.withdrawals || 0);
    }, 0);
  };

  // Calculate the total withdrawals
  const totalWithdrawals = calculateTotalWithdrawals();

    // Filter the data for items with an "Active" status
    const activeItems = users.filter(item => item.status === "Active");
  
    // Count the number of active items
    const activeCount = activeItems.length;
    //total users
const totalUsers = users.length

  
  let adminEdit = false;
  let columnsUsers = ['userID','Email', 'Name', 'Phone', 'Country',  'Status', 'UplineID', 'Date Joined', 'Password']
  let columnsTransaction = ['ID','User', 'Type', 'Amount', 'Description', 'Time', 'Status', 'Account']
  const navigate = useNavigate()
  useEffect(() => {
    if (!localStorage.getItem('udm_unm')) {
      navigate('/')
    }
  }, [navigate])

  return (
    <div style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
        <div className="w3-container">
          <div className="w3-row w3-left w3-padding-24">
            <p className="w3-large texxt-black bold margin-0">Welcome back</p>
          </div>
          <div className="w3-right flex-row w3-padding-24">
            <p className="w3-text-purple">
              Dashboard
            </p>
            <p className="w3-small padding-sm">
              <i class="fa-solid fa-angles-right"></i>
            </p>
            <p className="text-dark">Admin</p>

          </div>
        </div>
        <div className="w3-container">
          <div className="w3-row">
            <div className="w3-container w3-half">
              <Panel name='Total Balance' icon='fa-solid fa-wallet' perc='4.6' value={`${adminCurrency.currency} ${adminCurrency && formatNumber(totalBalance * adminCurrency.rate)}`} color='blue'/>
            </div>
            <div className="w3-container w3-half">
              <Panel name='Total Income' icon='fa-solid fa-coins' perc='4.6' value={`${adminCurrency.currency} ${adminCurrency && formatNumber(totalIncome * adminCurrency.rate)}`} color='pink'/>
            </div>
          </div>
          <div className="w3-row">
            <div className="w3-container w3-half">
              <Panel name='Total Deposits' icon='fa-solid fa-receipt' perc='4.6' value={`${adminCurrency.currency} ${adminCurrency && formatNumber(totalDeposits * adminCurrency.rate)}`} color='green' />
            </div>
            <div className="w3-container w3-half">
              <Panel name='Total Withdrawals' icon='fa-solid fa-percent' perc='4.6' value={`${adminCurrency.currency} ${ formatNumber(totalWithdrawals * adminCurrency.rate)}`} color='blue'/>
            </div>
          </div>
          <div className="w3-row">
            <div className="w3-container w3-half">
              <Panel name='Total Users' icon='fa-solid fa-user' perc='4.6' value={`${totalUsers}`} color='purple' />
            </div>
            <div className="w3-container w3-half">
              <Panel name='Active Users' icon='fa-solid fa-user-secret' perc='4.6' value={activeCount} color='yellow'/>
            </div>
          </div>
        </div>
        <div className="w3-container w3-padding">
          <Table name='Recent Users' columns={columnsUsers} data={latestUsers} edit={adminEdit} summary={true}/>
        </div>
        <div className="w3-container w3-padding">
          <Table  name='Recent Transactions' columns={columnsTransaction} data={latestTransactions} edit={adminEdit} summary={true}/>
        
        </div>
    </div>
  )
}

export default Dashboard