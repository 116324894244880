import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { MainContext } from '../contexts/mainContext'
import { AuthContext } from '../contexts/authContext'

const Navbar = (props) => {
  const {
    authUser,
    setAuthUser,
    isLogged,
    setIsLogged
} = useContext(AuthContext)
const {
  transactions,
  setTransactions,
  latestTransactions,
  setLatesTransactions,
  users,
  setLatestUsers,
  wallets,
  setWallets,
  admins,
  setAdmins,
  payment,
  setPayment,
  groups,
  setGroups,
  currency,
  setCurrency,
  category,
  setCategory,
  controls,
  setControls,
  latestUsers,
  setUsers,
  adminCurrency,
  setAdminCurrency,
  countries,
  setCountries,
  products,
  setProducts,
  isEdit,
  setIsEdit,
  isAdd,
  setIsAdd,
  admInf,
  setAdmInf,
} = useContext(MainContext)
  const [status, setStatus] = useState(true)
    const [small, setSmall] = useState(true)
    const location = useLocation()
    const checkDocumentWidth = () => {
        if (document.documentElement.clientWidth > 600) {
          setSmall(false);
        } else {
          setSmall(true);
        }
      };
      useEffect(() => {
        // Initial check when the component mounts
        checkDocumentWidth();
    
        // Listen for window resize events
        window.addEventListener('resize', checkDocumentWidth);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', checkDocumentWidth);
        };
      }, []);
      useEffect(() => {
        // Check if the current path is '/login'
        if (location.pathname === '/') {
          setStatus(false); // Hide the navbar
        } else {
          setStatus(true); // Show the navbar
        }
      }, [location.pathname]);

  return (
    <div className={(!status) ? 'hide' : 'w3-show'}>
          <div id={(small) ?  'nav' : 'na'} className={`w3-container ${(small) ? 'collapse' : 'w3-show'} w3-bar my-scroll-div w3-top w3-col l3 m3 s5`}>
          <div className=''>
          <div className="w3-panel">
            <p className='w3-medium logo'>ADMIN</p>
          </div>
          <div className='w3-bar flex-column'>
            <NavLink to='/dashboard' end className="w3-row navi flex-row w3-padding link w3-round-large">
                <p className='w3-large margin-0'>
                    <i class="fa-solid fa-gauge"></i>
                </p>
                <p className='w3-medium w3-padding margin-0'>Dashboard</p>
            </NavLink>
            <div style={{width: '100%'}} className="w3-row w3-bar-item">
            <p className='w3-text-grey bold margin-0'>Generals</p>
            </div>
            <NavLink to='/product' end className="w3-row navi flex-row w3-padding link w3-round-large">
                <p className='w3-large margin-0'>
                <i class="fa-solid fa-cart-shopping"></i>
                </p>
                <p className='w3-medium w3-padding margin-0'>Products</p>
            </NavLink>
            <NavLink to='/users' end className="w3-row navi flex-row w3-padding link w3-round-large">
                <p className='w3-large margin-0'>
                <i class="fa-solid fa-user"></i>
                </p>
                <p className='w3-medium w3-padding margin-0'>Users</p>
            </NavLink>
            <NavLink to='/wallet' end className="w3-row navi flex-row w3-padding link w3-round-large">
                <p className='w3-large margin-0'>
                <i class="fa-solid fa-wallet"></i>
                </p>
                <p className='w3-medium w3-padding margin-0'>Wallets</p>
            </NavLink>
            {admInf && (admInf.role == 'CEO') && <NavLink to='/admin' end className="w3-row navi flex-row w3-padding link w3-round-large">
                <p className='w3-large margin-0'>
                <i class="fa-solid fa-user-secret"></i>
                </p>
                <p className='w3-medium w3-padding margin-0'>Admins</p>
            </NavLink>}
            
            <div style={{width: '100%'}} className="w3-row w3-bar-item">
              <p className='w3-text-grey bold margin-0'>Controls</p>
            </div>
            <NavLink to='/transaction' className="w3-row navi flex-row w3-padding link w3-round-large">
                <p className='w3-large margin-0'>
                <i class="fa-solid fa-receipt"></i>
                </p>
                <p className='w3-medium w3-padding margin-0'>Transactions</p>
            </NavLink>
            <NavLink to='/gateway' className="w3-row navi flex-row w3-padding link w3-round-large">
                <p className='w3-large margin-0'>
                <i class="fa-solid fa-coins"></i>
                </p>
                <p className='w3-medium w3-padding margin-0'>Payment</p>
            </NavLink>
            <NavLink to='/group' className="w3-row navi flex-row w3-padding link w3-round-large">
                <p className='w3-large margin-0'>
                <i class="fa-solid fa-user-group"></i>
                </p>
                <p className='w3-medium w3-padding margin-0'>Groups</p>
            </NavLink>
            <NavLink to='/currency' className="w3-row navi flex-row w3-padding link w3-round-large">
                <p className='w3-large margin-0'>
                <i class="fa-solid fa-percent"></i>
                </p>
                <p className='w3-medium w3-padding margin-0'>Currency</p>
            </NavLink>
            <NavLink to='/category' className="w3-row navi flex-row w3-padding link w3-round-large">
                <p className='w3-large margin-0'>
                <i class="fa-solid fa-table-cells-large"></i>
                </p>
                <p className='w3-medium w3-padding margin-0'>Category</p>
            </NavLink>
            <NavLink to='/controls' className="w3-row navi flex-row w3-padding link w3-round-large">
                <p className='w3-large margin-0'>
                <i class="fa-solid fa-gamepad"></i>
                </p>
                <p className='w3-medium w3-padding margin-0'>Controls</p>
            </NavLink>
            <div style={{width: '100%'}} className="w3-row w3-bar-item">
              <p className='w3-text-grey bold margin-0'>Layout</p>
            </div>
            <NavLink to='/layout' className="w3-row navi flex-row w3-padding link w3-round-large">
                <p className='w3-large margin-0'>
                <i class="fa-solid fa-pager"></i>
                </p>
                <p className='w3-medium w3-padding margin-0'>Layout</p>
            </NavLink>
          </div>
          </div>
        </div>
    </div>

    
  )
}

export default Navbar