import React from 'react'

const Inputs = (props) => {
  function handleInpt(event) {
    props.handleInpt(event)
  }
  return (
    <div className={`w3-padding ${(props.height) ? props.height : ''}`}>
      <p className={`w3-large ${(props.label) ? 'w3-show' : 'w3-hide'} text-dark margin-0`}>{props.label}</p>
      <div className='w3-row flex-row w3-text-grey w3-padding inputs w3-round-large'>
        <p className="w3-large  margin-0">
            <i className={props.icon}></i>
        </p>
        <input onChange={handleInpt} className='padding-sm' style={{width: '100%', outline: 'none', border: 'none'}} type={props.type} placeholder={props.placeholder} value={props.value} name={props.name} />
    </div>
    </div>
    
  )
}

export default Inputs