import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Navbar from './components/navbar';
import Bottombar from './components/bottomBar';
import Topbar from './components/topbar';
import EditableTable from './components/editableTable';
import Product from './pages/products';
import Users from './pages/user';
import Wallets from './pages/wallets';
import Admins from './pages/admin';
import Transaction from './pages/transaction';
import Gateway from './pages/gateway';
import Groups from './pages/groups';
import Currency from './pages/currency';
import Category from './pages/category';
import Controls from './pages/controlMin';
import Layout from './pages/layout';
import { useCallback, useContext, useEffect, useState } from 'react';
import {AuthContext} from './contexts/authContext';
import MainProvider from './contexts/mainContext';
import ToWith from './pages/toWith';
import TopDep from './pages/toDep';
function App() {
  const {
    authUser,
    setAuthUser,
    isLogged,
    setIsLogged
} = useContext(AuthContext)
  const [small, setSmall] = useState(true)
  const checkDocumentWidth = () => {
      if (document.documentElement.clientWidth > 600) {
        setSmall(false);
      } else {
        setSmall(true);
      }
    };
  
    useEffect(() => {
      // Initial check when the component mounts
      checkDocumentWidth();
  
      // Listen for window resize events
      window.addEventListener('resize', checkDocumentWidth);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', checkDocumentWidth);
      };
    }, []);

  return (
    <div className="App">
      <MainProvider>
        <BrowserRouter>

          <Navbar />
          <Topbar authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged} />
          <div className={(small) ? 'margin-tp' : 'h'}>
          <Routes>
            <Route path='/dashboard' element={<Dashboard/>}/>
            <Route path='/' element={<Login authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/edit' element={<EditableTable authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/product' element={<Product authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/users' element={<Users authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/wallet' element={<Wallets authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/admin' element={<Admins authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/transaction' element={<Transaction authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/gateway' element={<Gateway authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/group' element={<Groups authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/currency' element={<Currency authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/category' element={<Category authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/controls' element={<Controls authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/layout' element={<Layout authUser={authUser} setAuthUser={setAuthUser} isLogged={isLogged} setIsLogged={setIsLogged}/>}/>
            <Route path='/to_with' element={<ToWith/>}/>
            <Route path='/to_dep' element={<TopDep/>}/>
          </Routes>
            </div>
        </BrowserRouter>
      </MainProvider>
    </div>
  );
}

export default App;
