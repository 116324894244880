import React, { createContext, useState, useEffect, useContext } from 'react';

export const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

function AuthProvider(props) {
    const [authUser, setAuthUser] = useState(null);
    const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {
        // Check localStorage for existing auth data on component mount
        const userData = localStorage.getItem('udm_unm');
        console.log('Fetched user data:', userData)
        if (userData) {
            setAuthUser(JSON.parse(userData));
            setIsLogged(true);
        } else {
            setIsLogged(false);
        }
    }, []); // Empty dependency array ensures this runs once on mount

    const login = (userData) => {
        localStorage.setItem('adm_unm', JSON.stringify(userData));
        setAuthUser(userData);
        setIsLogged(true);
    };

    const logout = () => {
        localStorage.removeItem('adm_unm');
        setAuthUser(null);
        setIsLogged(false);
    };

    const value = {
        authUser,
        setAuthUser,
        isLogged,
        setIsLogged,
        login,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
