import React from 'react'

const Button = (props) => {
    function sendBtn(){
        props.handleFunc()
    }
  return (
    <div className="w3-row padding-sm">
            <button onClick={sendBtn} id={props.id} style={{width: `${props.width}%`}} className='btn btn-primary w3-margin w3-centered'>{props.value}</button>
    </div>

  )
}

export default Button