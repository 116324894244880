import React, { useContext, useState } from 'react'
import Button from './button'
import EditableTable from './editableTable'

const Top = (props) => {
  const [modalData, setModalData] = useState({
    type: 'edit'
  });
  const handleBtn = () => {
    props.setShowModal(true)
  }
  let isAdd = props.isAdd
  const handleEditClick = (row) => {
    setModalData(row);
    props.setShowModal(true);
  };

  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <div className="w3-container">
          <div className={`w3-row ${(isAdd) ? 'w3-show' : 'w3-hide'} w3-right w3-padding-24`}>
            <Button handleFunc={handleBtn} id='add-user' value={props.value} width='100'/>
          </div>
          <div className="w3-left flex-row w3-padding-24">
            <p className="w3-text-purple">
              {props.name}
            </p>
            <p className="w3-small padding-sm">
              <i class="fa-solid fa-angles-right"></i>
            </p>
            <p className="text-dark">Admin</p>

          </div>
          <EditableTable 
          handleProdName={props.handleProdName} 
          show={props.showModal} 
          value="Add Products" 
          handleClose={handleClose} 
          data={modalData || {}} 
          inputs={props.inputs} 
          setData={setModalData}
        />
    </div>
  )
}

export default Top