import React from 'react'

function Modal(props) {
    function handleRedir() {
        window.location.replace('/dashboard')
    }
  return (
    <div className={`modal fade ${props.show ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: props.show ? 'rgba(0,0,0,0.5)' : 'transparent' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.isSuccess ? 'Login Successful Redirecting...' : 'Login Error'}</h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={props.handleClose}></button>
          </div>
          <div className="modal-body">
            <p>{props.message}</p>
          </div>
          <div className="modal-footer">
            {props.isSuccess && <button type="button" className={`btn btn-${props.isSuccess ? 'success' : 'danger'}`} onClick={handleRedir}>
              Ok
            </button>}
            {!props.isSuccess && <button type="button" className={`btn btn-${props.isSuccess ? 'success' : 'danger'}`} onClick={props.handleClose}>
              Close
            </button>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal