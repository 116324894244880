import React from 'react'

const Panel = (props) => {
  return (
    <div className='w3-panel w3-padding-24 w3-round-large w3-card w3-white'>
        <div className="w3-left grid-center w3-padding">
            <div style={{height: '70px', width: '70px'}} className={`badge w3-round grid-center blur-${props.color}`}>
                <p style={{height: '60%', width: '60%'}} className={`w3-${props.color} grid-center badge margin-0`}>
                    <span className='w3-text-white margin-0 text-center bold w3-medium'>
                        <i class={props.icon}></i>
                    </span>
                </p>
            </div>
        </div>
        <div style={{width: '60%'}} className="w3-right text-dark">
            <p className="w3-large margin-0">{props.name}</p>
            <p className="w3-large bold margin-0">{props.value}</p>
            <p className="w3-small margin-0">
                Increase by <span className='badge blur-green w3-text-green'>{props.perc}%</span> this month
            </p>
        </div>
    </div>
  )
}

export default Panel