import React, { useContext, useEffect, useState } from 'react'
import Inputs from './inputs'
import vatar from '../media/images/avatar.png'
import { AuthContext } from '../contexts/authContext'
import { useLocation } from 'react-router-dom'
import { MainContext } from '../contexts/mainContext'
const Topbar = (props) => {
  const {
    authUser,
    setAuthUser,
    isLogged,
    setIsLogged
  } = useContext(AuthContext)
  const {
    transactions,
    setTransactions,
    latestTransactions,
    setLatesTransactions,
    users,
    setLatestUsers,
    wallets,
    setWallets,
    admins,
    setAdmins,
    payment,
    setPayment,
    groups,
    setGroups,
    currency,
    setCurrency,
    category,
    setCategory,
    controls,
    setControls,
    latestUsers,
    setUsers,
    adminCurrency,
    setAdminCurrency,
    countries,
    setCountries,
    products,
    setProducts,
    admInf,
    setAdmInf
  } = useContext(MainContext)
  const location = useLocation()
  const [isVisible, setIsVisible] = useState(false)
  const [small, setSmall] = useState(true)
  const checkDocumentWidth = () => {
      if (document.documentElement.clientWidth > 600) {
        setSmall(false);
      } else {
        setSmall(true);
      }
    };
  
    useEffect(() => {
      // Initial check when the component mounts
      checkDocumentWidth();
  
      // Listen for window resize events
      window.addEventListener('resize', checkDocumentWidth);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', checkDocumentWidth);
      };
    }, []);
    useEffect(() => {
      // Check if the current path is '/login'
      if (location.pathname === '/') {
        setIsVisible(false); // Hide the navbar
      } else {
        setIsVisible(true); // Show the navbar
      }
    }, [location.pathname]);
  return (
    <div className={(isVisible ? 'w3-show' : 'hide')}>
          <div style={{maxHeight: '8vh'}} className={`w3-container ${(small) ? 'w3-top' : 'topBar'} w3-col l9 m9 s12 w3-right j-space w3-white flex-row w3-card w3-padding`}>
        <div className="w3-panel">
            <p data-bs-toggle="collapse" data-bs-target="#nav" className='w3-xlarge'>
            <i class="fa-solid fa-bars"></i>
            </p>
        </div>
        <div style={{width: '60%'}} className="w3-panel grid-center">
          <div className='input-max'>
            <div className='w3-row flex-row w3-text-grey padding-sm inputs w3-round-large'>
              <p className="w3-medium  margin-0">
                  <i className='fa-solid fa-magnifying-glass'></i>
              </p>
              <input className='padding-sm' style={{width: '100%', outline: 'none', border: 'none'}} type='search' placeholder='Search here...' />
            </div>
          </div>
        </div>
        <div className="w3-panel w3-row">
          <div className="w3-left w3-padding">
            <img src={vatar} className='w3-circle' width={30} alt="" />
          </div>
          <div className="w3-right w3-hide-small">
          {authUser && <p className="w3-large margin-0">{authUser.username} </p>}
            <p className="w3-medium w3-text-grey margin-0">{(admInf) && <span>{admInf.role} </span>}</p>
          </div>
        </div>
    </div>
    </div>

  )
}

export default Topbar