import React from 'react'

function ButtonLoad(props) {
  return (
    <div className="w3-row padding-sm">
            <button id={props.id} style={{width: `${props.width}%`}} className='btn btn-primary w3-margin w3-centered' disabled>
                <span className='spinner-border spinner-border-sm'></span>
                {props.value}
                </button>
    </div>
  )
}

export default ButtonLoad